import React from 'react';
import Link from 'next/link';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { PackageType } from '~/types/create-agent-form-type';
import { packages } from '~/constants/pre-portal-package';

type Props = {
    onSelectPackage?: (selectedPackage: PackageType) => void;
};

const PrePortalPaywall = ({ onSelectPackage = () => {} }: Props) => (
    <div className="flex flex-col gap-6 ">
        <strong className="text-xl text-primary-500">Choose your package:</strong>
        <div className="grid w-full grid-cols-1 gap-6 pb-10 min-[552px]:grid-cols-2 sm:flex-row lg:grid-cols-3">
            <div className="flex w-full flex-col justify-between rounded-2xl border-2 border-gray-300">
                <div className="px-6 pt-12">
                    <div className="my-1 mt-2 flex items-center gap-x-3 text-base font-semibold">
                        <span className="pt-[4px] text-gray-500">SELLING AGENT</span>
                    </div>
                    <span className="text-lg font-semibold">AUD $0 per month</span>
                </div>
                <div className="justify-betweena flex h-full flex-col justify-end p-6">
                    <div className="mt-[47px] h-[12.5rem]">
                        <ul className="space-y-4 text-gray-900">
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">List Properties Free</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Pre-Market listings</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Off-Market listings</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Receive buyer offers</span>
                            </li>
                        </ul>
                    </div>
                    {/*<button*/}
                    {/*    onClick={() => onSelectPackage(packages.SELLING_AGENT)}*/}
                    {/*    type="button"*/}
                    {/*    className="mt-6 rounded-lg bg-gray-900 py-4 text-sm text-white"*/}
                    {/*>*/}
                    {/*    <strong>Connect Now</strong>*/}
                    {/*</button>*/}
                    <Link href={'/agency-sign-up'}>
                        <a
                            className={
                                'mt-6 rounded-lg bg-gray-900 py-4 text-center  text-sm font-semibold text-white hover:bg-gray-900/80 hover:text-white sm:text-base'
                            }
                        >
                            Connect Now
                        </a>
                    </Link>
                </div>
            </div>
            {/* <div className="flex w-full flex-col overflow-hidden rounded-2xl border-2 border-primary-500"> */}
            {/*    <div className="flex flex-col px-6 pt-6"> */}
            {/*        /!* <p className="w-fit rounded bg-red-500 px-2 text-xs text-white">MVP</p> *!/ */}
            {/*        <div className="my-1 flex flex-col gap-x-3 pt-3 text-base font-semibold"> */}
            {/*            <span className="pt-[2px] leading-snug text-primary-500"> */}
            {/*                BUYING AGENT <span className="text-sm font-medium text-primary-500">(BASIC)</span> */}
            {/*            </span> */}
            {/*        </div> */}
            {/*        <span className="text-lg font-semibold leading-snug text-gray-900">AUD $97 per month</span> */}
            {/*    </div> */}
            {/*    <div className="justify-betweena flex h-full flex-col justify-end p-6"> */}
            {/*        /!* <div className="mt-[46px] h-[12.5rem]"> *!/ */}
            {/*        <div className="mt-[46px] h-[12.5rem]"> */}
            {/*            <ul className="space-y-4 text-base text-gray-900"> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">Pre portal & Off-Market</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">Live Chat</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">Negotiation service</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">0.1% Transaction Fee</span> */}
            {/*                </li> */}
            {/*            </ul> */}
            {/*        </div> */}
            {/*        <button */}
            {/*            type="button" */}
            {/*            onClick={() => onSelectPackage(packages.BUYING_AGENT)} */}
            {/*            className="mt-6 rounded-lg bg-primary-500 py-4 text-sm text-white" */}
            {/*        > */}
            {/*            <strong>Sign Up</strong> */}
            {/*        </button> */}
            {/*    </div> */}
            {/* </div> */}
            <div className="flex w-full flex-col overflow-hidden rounded-2xl border-2 border-primary-500">
                <div className="flex flex-col px-6 pt-6">
                    {/* <p className="w-fit rounded bg-red-500 px-2 text-xs text-white">MVP</p> */}
                    <div className="my-1 flex flex-col gap-x-3 pt-3 text-base font-semibold">
                        <span className="pt-[2px] leading-snug text-primary-500">
                            BUYING AGENT <span className="text-sm font-medium text-primary-500">(ALERTS)</span>
                        </span>
                    </div>
                    <span className="text-lg font-semibold leading-snug text-gray-900">AUD $0 per month</span>
                </div>
                <div className="justify-betweena flex h-full flex-col justify-end p-6">
                    {/* <div className="mt-[46px] h-[12.5rem]"> */}
                    <div className="mt-[46px] h-[12.5rem]">
                        <ul className="space-y-4 text-base text-gray-900">
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Unlimited Briefs</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Pay to unlock alerts</span>
                            </li>
                        </ul>
                    </div>
                    <button
                        type="button"
                        onClick={() => onSelectPackage(packages.BUYING_AGENT_FREE)}
                        className="mt-6 rounded-lg bg-primary-500 py-4 text-sm text-sm text-white hover:bg-primary-500/80 hover:text-white sm:text-base"
                    >
                        <strong>Sign Up</strong>
                    </button>
                </div>
            </div>
            <div className="flex w-full flex-col overflow-hidden rounded-2xl border-[1px] border-gray-300">
                {/* <div className="mb-4 bg-gray-400 py-[0.688rem] text-center text-xs text-white">Coming Soon</div> */}
                {/* <div className="flex flex-col px-6"> */}
                {/*    <div className="my-1 flex flex-col gap-x-3 text-base font-semibold"> */}
                {/*        <span className="min-[1280px]:whitespace-nowrapa leading-snug text-gray-500"> */}
                {/*            BUYING AGENT <span className="text-sm font-medium text-gray-500">(DEALS)</span> */}
                {/*        </span> */}
                {/*    </div> */}
                {/*    <span className="text-lg font-semibold leading-snug text-gray-900">AUD $297 per month</span> */}
                {/* </div> */}
                <div className="flex flex-col px-6 pt-6">
                    {/*<p className="w-fit rounded bg-red-500 px-2 text-xs text-white">MVP</p>*/}
                    <div className="my-1 flex flex-col gap-x-3 pt-3 text-base font-semibold">
                        <span className="pt-[2px] leading-snug text-primary-500">
                            BUYING AGENT <span className="text-sm font-medium text-primary-500">(DEALS)</span>
                        </span>
                    </div>
                    <span className="text-lg font-semibold leading-snug text-gray-900">AUD $297 per month</span>
                </div>
                <div className="justify-betweena flex h-full flex-col justify-end px-6 pb-6">
                    {/*<span className="text-xs font-semibold text-gray-900">All Free features plus:</span>*/}
                    <div className="mt-2 h-[12.5rem]">
                        <ul className="space-y-4 text-gray-900">
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Unlimited Briefs & Alerts</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Agent Connect</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Marketplace</span>
                            </li>
                            <li className="flex items-start text-sm font-light">
                                <FA icon={faCheckCircle} size="lg" />
                                <span className="pl-1">Agent directory</span>
                            </li>
                        </ul>
                    </div>
                    {/* <button */}
                    {/*    type="button" */}
                    {/*    onClick={() => onSelectPackage(packages.ADVANCED)} */}
                    {/*    className="mt-6 rounded-lg bg-gray-900 py-4 text-sm text-white" */}
                    {/* > */}
                    {/*    <strong>Apply Now</strong> */}
                    {/* </button> */}
                    <button
                        type="button"
                        onClick={() => onSelectPackage(packages.ADVANCED)}
                        className="mt-6 rounded-lg bg-primary-500 py-4 text-sm text-white hover:bg-primary-500/80 hover:text-white sm:text-base"
                    >
                        {/* <strong>Sign Up</strong> */}
                        <strong>7 Day Free Trial</strong>
                    </button>
                </div>
            </div>
            {/* <div className="flex w-full flex-col overflow-hidden rounded-2xl border-[1px] border-gray-300"> */}
            {/*    <div className="mb-4 bg-gray-400 py-[0.688rem] text-center text-xs text-white">Coming Soon</div> */}
            {/*    <div className="flex flex-col px-6"> */}
            {/*        <div className="my-1 flex flex-col gap-x-3 text-base font-semibold"> */}
            {/*            <span className="leading-snug text-gray-500"> */}
            {/*                BUYING AGENT <span className="text-sm font-medium text-gray-500">(PRO)</span> */}
            {/*            </span> */}
            {/*        </div> */}
            {/*        <span className="text-lg font-semibold leading-snug text-gray-900">AUD $1,997 per month</span> */}
            {/*    </div> */}
            {/*    <div className="justify-betweena flex h-full flex-col justify-end px-6 pb-6"> */}
            {/*        <span className="text-xs font-semibold text-gray-900">All Deals features plus:</span> */}
            {/*        <div className="mt-2 h-[12.5rem]"> */}
            {/*            <ul className="space-y-4 text-gray-900"> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">CRM & Buyer Management</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">Integrations</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">Direct Negotiation</span> */}
            {/*                </li> */}
            {/*                <li className="flex items-start text-sm font-light"> */}
            {/*                    <FA icon={faCheckCircle} size="lg" /> */}
            {/*                    <span className="pl-1">0.1% Transaction Fee</span> */}
            {/*                </li> */}
            {/*            </ul> */}
            {/*        </div> */}
            {/*        <button */}
            {/*            type="button" */}
            {/*            onClick={() => onSelectPackage(packages.PRO)} */}
            {/*            className="mt-6 rounded-lg bg-gray-900 py-4 text-sm text-white" */}
            {/*        > */}
            {/*            <strong>Apply Now</strong> */}
            {/*        </button> */}
            {/*    </div> */}
            {/* </div> */}
        </div>
    </div>
);

export default PrePortalPaywall;
